<template>
    <!--sidebar starts-->
    <div class="main">
        <div class="sidebar">
            <div class="logo_div">
                <img src="../assets/logo.png" />
                <div class="sidebar-version">Version: {{$appVersion}}</div>
            </div>
            <section>
                <nav>
                    <router-link to="/pages/Dashboard" title="DASHBOARD">
                        <i class="material-icons">dashboard</i>
                        <span>DASHBOARD</span>
                    </router-link>

                    <a
                        class="subtrigger"
                        title="CREATE BOOKING"
                        v-if="
                            auth().user_type == 'hub_admin' ||
                                auth().user_type == 'super_admin'
                        "
                    >
                        <i class="material-icons">assignment</i>
                        <span>MAILROOM</span>
                    </a>

                    <div class="submenu">
                        <router-link
                            to="/pages/MailRoom"
                            title="MAILROOM"
                            v-if="
                                auth().user_type == 'hub_user' ||
                                    auth().user_type == 'hub_admin'
                            "
                        >
                            <i class="material-icons">email</i>
                            <span>ENCODE INBOUND SHIPMENTS</span>
                        </router-link>

                        <router-link
                            to="/pages/PostalTransactionSummary"
                            title="ENCODE POSTAL SERVICE JOBS"
                            v-if="auth().user_type == 'hub_admin'"
                        >
                            <i class="material-icons">receipt</i>
                            <span>ENCODE POSTAL SERVICE JOBS</span>
                        </router-link>

                        <router-link
                            to="/pages/BulkBooking"
                            title="INBOUND BULK BOOKING"
                            v-if="auth().user_type == 'hub_admin'"
                        >
                            <i class="material-icons">collections</i>
                            <span>INBOUND BULK BOOKING</span>
                        </router-link>

                        <router-link
                            to="/pages/ViewPending"
                            title="VIEW MAILROOM JOBS"
                            v-if="auth().user_type != 'customer_service_user'"
                        >
                            <i class="material-icons">inbox</i>
                            <span>VIEW MAILROOM JOBS</span>
                        </router-link>

                        <router-link
                            to="/pages/DispatchMessenger"
                            title="DISPATCH MESSENGER"
                            v-if="
                                usergood.is_dbsa === 0 &&
                                    auth().user_type == 'hub_admin'
                            "
                        >
                            <i class="material-icons">inbox</i>
                            <span>DISPATCH MESSENGER</span>
                        </router-link>
                    </div>

                    <a
                        class="subtrigger"
                        title="CREATE BOOKING"
                        v-if="auth().user_type != 'customer_service_user' && outboundEnabled"
                    >
                        <i class="material-icons">assignment</i>
                        <span>COURIER</span>
                    </a>

                    <div class="submenu" v-if="outboundEnabled">
                        <router-link
                            to="/pages/QuoteAndBook"
                            title="QUICK BOOKING"
                            v-if="auth().user_type != 'super_admin'"
                        >
                            <i class="material-icons">assignment</i>
                            <span>COURIER BOOKING</span>
                        </router-link>

                        <router-link
                            to="/pages/BulkBookingUser"
                            title="BULK BOOKING"
                            v-if="auth().user_type != 'super_admin'"
                        >
                            <i class="material-icons">collections</i>
                            <span>COURIER BULK BOOKING</span>
                        </router-link>

                        <!-- 
						<router-link to="/pages/GenerateLabel" title="GENERATE LABEL" v-if="usergood.is_dbsa == 0 && auth().user_type != 'super_admin'">
							<i class="material-icons">inbox</i>
							<span>GENERATE LABEL</span>	
						</router-link> 
						-->

                        <router-link
                            to="/pages/Dispatch"
                            title="DISPATCH"
                            v-if="
                                usergood.is_dbsa == 0 &&
                                    auth().user_type != 'super_admin' &&
                                      auth().user_type != 'user'
                            "
                        >
                            <i class="material-icons">inbox</i>
                            <span>DISPATCH COURIER</span>
                        </router-link>

                        <router-link
                            to="/pages/Manifest"
                            title="MANIFEST"
                            v-if="
                                auth().user_type != 'customer_service_user' &&
                                    auth().user_type != 'super_admin' &&
                                      auth().user_type != 'user'
                            "
                        >
                            <i class="material-icons">receipt</i>
                            <span>MANIFEST</span>
                        </router-link>

                        <router-link
                            to="/pages/ViewCourierPending"
                            title="VIEW COURIER JOBS"
                            v-if="auth().user_type != 'customer_service_user'"
                        >
                            <i class="material-icons">inbox</i>
                            <span>VIEW COURIER JOBS</span>
                        </router-link>

                        <router-link
                            to="/pages/TransportStatusBooking"
                            title="TRANSPORT STATUS UPLOAD"
                            v-if="auth().user_type == 'hub_admin'"
                        >
                            <i class="material-icons">collections</i>
                            <span>BULK TRANSPORT STATUS UPLOAD</span>
                        </router-link>
                    </div>

                    <a
                        class="subtrigger"
                        title="CREATE BOOKING"
                        v-if="auth().user_type != 'customer_service_user'
                        && auth().user_type != 'user'"
                    >
                        <i class="material-icons">assignment</i>
                        <span>TRANSPORT</span>
                    </a>

                    <div class="submenu">
                        <router-link
                            to="/pages/QuickTransportBooking"
                            title="QUICK TRANSPORT BOOKING"
                            v-if="auth().user_type != 'super_admin'"
                        >
                            <i class="material-icons">collections</i>
                            <span>TRANSPORT</span>
                        </router-link>

                        <router-link
                            to="/pages/ViewPendingTransport"
                            title="VIEW TRANSPORT JOBS"
                            v-if="auth().user_type != 'customer_service_user'"
                        >
                            <i class="material-icons">inbox</i>
                            <span>VIEW TRANSPORT JOBS</span>
                        </router-link>
                    </div>

                    <a
                        class="subtrigger"
                        title="CREATE BOOKING"
                        v-if="auth().user_type != 'customer_service_user'&&
                        auth().user_type != 'user'"
                    >
                        <i class="material-icons">assignment</i>
                        <span>ENCODE</span>
                    </a>

                    <div class="submenu">
                        <router-link
                            to="/pages/Encoding"
                            title="ENCODE"
                            v-if="auth().user_type != 'super_admin'"
                        >
                            <i class="material-icons">code</i>
                            <span>ENCODE</span>
                        </router-link>

                        <a
                            class="subtrigger"
                            title="DISPATCH"
                            v-if="
                                auth().user_type != 'customer_service_user' &&
                                    auth().user_type != 'super_admin'
                            "
                        >
                            <i class="material-icons">receipt</i>
                            <span>DISPATCH ENCODE</span>
                        </a>

                        <div class="submenu">
                            <router-link
                                to="/pages/GenerateSlip"
                                title="GENERATE SLIP"
                            >
                                <i class="material-icons">receipt</i>
                                <span>GENERATE SLIP</span>
                            </router-link>

                            <router-link
                                to="/pages/ViewTransmittalSlipBranch"
                                title="VIEW TRANSMITTAL SLIP"
                            >
                                <i class="material-icons">receipt</i>
                                <span>VIEW TRANSMITTAL SLIP</span>
                            </router-link>

                            <router-link
                                to="/pages/CreateDispatchDeliveryNote"
                                title="DELIVERY DISPATCH NOTE"
                            >
                                <i class="material-icons">receipt</i>
                                <span>DELIVERY DISPATCH NOTE</span>
                            </router-link>
                        </div>

                        <a
                            class="subtrigger"
                            title="HUB DISPATCH"
                            v-if="
                                auth().user_type == 'hub_user' ||
                                    auth().user_type == 'hub_admin'
                            "
                        >
                            <i class="material-icons">receipt</i>
                            <span>HUB DISPATCH</span>
                        </a>

                        <div class="submenu">
                            <router-link
                                to="/pages/CreateTransmittalSlipHubAdmin"
                                title="GENERATE SLIP SEARCH"
                            >
                                <i class="material-icons">receipt</i>
                                <span>GENERATE SLIP SEARCH</span>
                            </router-link>

                            <router-link
                                to="/pages/CreateTransmittalSlipHubAdminByScan"
                                title="GENERATE SLIP SCAN"
                            >
                                <i class="material-icons">receipt</i>
                                <span>GENERATE SLIP SCAN</span>
                            </router-link>

                            <router-link
                                to="/pages/ViewTransmittalSlip"
                                title="VIEW TRANSMITTAL SLIP"
                            >
                                <i class="material-icons">receipt</i>
                                <span>VIEW TRANSMITTAL SLIP</span>
                            </router-link>

                            <router-link
                                to="/pages/CreateDispatchDeliveryNote/Hub"
                                title="DELIVERY DISPATCH NOTE"
                            >
                                <i class="material-icons">receipt</i>
                                <span>DELIVERY DISPATCH NOTE</span>
                            </router-link>
                        </div>

                        <router-link
                            to="/pages/ViewEncodePending"
                            title="VIEW ENCODE JOBS"
                            v-if="auth().user_type != 'customer_service_user'"
                        >
                            <i class="material-icons">inbox</i>
                            <span>VIEW ENCODE JOBS</span>
                        </router-link>
                    </div>

                    <router-link
                        to="/pages/Draft"
                        title="DRAFT"
                        v-if="
                            auth().user_type != 'customer_service_user' &&
                                auth().user_type != 'super_admin'"
                    >
                        <i class="material-icons">inbox</i>
                        <span>DRAFT</span>
                    </router-link>

                    <!-- <router-link
                        to="/pages/ViewPendingTransaction"
                        title="VIEW POSTAL SERVICE JOBS "
                        v-if="auth().user_type == 'hub_admin'"
                    >
                        <i class="material-icons">inbox</i>
                        <span>VIEW POSTAL SERVICE JOBS </span>
                    </router-link> -->

                    <a
                        class="subtrigger"
                        title="POSTAL SERVICES"
                        v-if="
                            auth().user_type != 'customer_service_user' &&
                                auth().user_type != 'super_admin'
                        "
                    >
                        <i class="material-icons">assignment</i>
                        <span>POSTAL SERVICES</span>
                    </a>

                    <div class="submenu">
                        <router-link
                            to="/pages/PostalPrepaidService"
                            title="ISSUE POSTAL SERVICES"
                            v-if="
                                auth().user_type == 'user' ||
                                    auth().user_type == 'hub_admin'
                            "
                        >
                            <i class="material-icons">code</i>
                            <span v-if="auth().user_type == 'user'">
                                REQUEST POSTAL SERVICES</span
                            >
                            <span v-if="auth().user_type == 'hub_admin'">
                                ISSUE POSTAL SERVICES</span
                            >
                        </router-link>

                        <router-link
                            to="/pages/ViewPostalServices"
                            title="VIEW POSTAL SERVICES"
                            v-if="auth().user_type != 'customer_service_user'"
                        >
                            <i class="material-icons">inbox</i>
                            <span>VIEW POSTAL SERVICES</span>
                        </router-link>
                    </div>

                    <router-link to="/pages/TrackTrace" title="TRACK AND TRACE">
                        <i class="material-icons">motorcycle</i>
                        <span>TRACK AND TRACE</span>
                    </router-link>

                    <router-link
                        to="/pages/ViewProcessed"
                        title="VIEW RECEIPTS "
                        v-if="auth().user_type != 'customer_service_user'&&
                        auth().user_type != 'user'"
                    >
                        <i class="material-icons">history</i>
                        <span>VIEW RECEIPTS </span>
                    </router-link>

                    <a class="subtrigger" title="REPORTS">
                        <i class="material-icons">equalizer</i>
                        <span>REPORTS</span>
                    </a>

                    <div class="submenu">

                        <!-- INBOUND REPORTS START -->
                        <a
                            class="subtrigger"
                            title="INBOUND REPORT"
                        >
                            <i class="material-icons">home</i>
                            <span>INBOUND REPORTS</span>
                        </a>

                        <div class="submenu">
                            <router-link
                                to="/pages/InboundDeliveryReport"
                                title="INBOUND DELIVERY REPORT"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>INBOUND DELIVERY REPORT</span>
                            </router-link>

                            <router-link
                                to="/pages/ReportsMailroom"
                                title="MAILROOM REPORTS"
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>MAILROOM REPORTS</span>
                            </router-link>
                        </div>

                        <!-- INBOUND REPORTS END -->

                        <!-- OUTBOUND REPORTS START -->
                        <a
                            class="subtrigger"
                            title="OUTBOUND REPORTS"
                        >
                            <i class="material-icons">domain</i>
                            <span>OUTBOUND REPORTS</span>
                        </a>

                        <div class="submenu">
                            <router-link
                                to="/pages/OutboundInvoiceReport"
                                title="OUTBOUND INVOICE REPORT"
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>OUTBOUND INVOICE REPORT</span>
                            </router-link>

                            <router-link
                                to="/pages/OutboundTransactionReport"
                                title="OUTBOUND TRANSACTIONS"
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>OUTBOUND TRANSACTIONS</span>
                            </router-link>

                            <router-link
                                to="/pages/OutboundPostalReport"
                                title="OUTBOUND POSTAL REPORT"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>OUTBOUND POSTAL REPORT</span>
                            </router-link>

                            <router-link
                                to="/pages/OutboundMarkupReport"
                                title="OUTBOUND MARKUP REPORT"
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>OUTBOUND MARKUP REPORT</span>
                            </router-link>

                        </div>

                        <!-- OUTBOUND REPORTS END -->

                        <!-- POSTAL ITEMS REPORTS START -->
                        <a
                            class="subtrigger"
                            title="POSTAL ITEMS REPORTS"
                        >
                            <i class="material-icons">mail</i>
                            <span>POSTAL ITEMS REPORTS</span>
                        </a>

                        <div class="submenu">
                            <router-link
                                to="/pages/PostalItemInvoiceReport"
                                title="POSTAL ITEM INVOICE REPORT"
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>POSTAL ITEM INVOICE REPORT</span>
                            </router-link>

                            <router-link
                                to="/pages/ReportsMailroom"
                                title="MAILROOM REPORTS"
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>MAILROOM REPORTS</span>
                            </router-link>

                            <router-link
                                to="/pages/ReportPostalItemSalesTracker"
                                title="POSTAL ITEMS SALES TRACKER"
                                v-if="
                                    auth().user_type == 'hub_admin' ||
                                        auth().user_type == 'super_admin'
                                "
                            >
                                <span class="nested-sub-menu"></span>
                                <span>POSTAL ITEMS SALES TRACKER </span>
                            </router-link>

                            <router-link
                                to="/pages/ReportPostalServiceIssue"
                                title="POSTAL SERVICES ISSUE REPORT "
                                v-if="
                                    auth().user_type == 'hub_admin' ||
                                        auth().user_type == 'super_admin'
                                "
                            >
                                <span class="nested-sub-menu"></span>
                                <span>POSTAL SERVICES ISSUE REPORT</span>
                            </router-link>

                            <router-link
                                to="/pages/PostalTransactionSummaryReport"
                                title="POSTAL ENCODE SUMMARY REPORT"
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>POSTAL ENCODE SUMMARY REPORT</span>
                            </router-link>

                            <router-link
                                to="/pages/ReportExpressRegisteredPost"
                                title="EXPRESS REGISTERED POST"
                                v-if="
                                    auth().user_type == 'hub_admin' ||
                                        auth().user_type == 'super_admin'
                                "
                            >
                                <span class="nested-sub-menu"></span>
                                <span>EXP & REG POST REGISTER</span>
                            </router-link>

                            <router-link
                                to="/pages/ReportPostalServiceRequest"
                                title="POSTAL SERVICES REQUEST REPORT"
                                v-if="auth().user_type == 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>POSTAL SERVICES REQUEST REPORT</span>
                            </router-link>

                        </div>

                        <!-- POSTAL ITEMS REPORTS END -->

                        <!-- WEEKLY INVOICE RECONCILIATION REPORTS START -->
                        <a
                            class="subtrigger"
                            title="INVOICE RECONCILIATION REPORT"
                            v-if="auth().user_type != 'user'"
                        >
                            <i class="material-icons">sync</i>
                            <span>RECONCILIATION REPORTS</span>
                        </a>

                        <div class="submenu">

                            <router-link
                                to="/pages/CourierBillingReport"
                                title="WEEKLY COURIER BILLING REPORT"
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>WEEKLY COURIER BILLING REPORT</span>
                            </router-link>

                            <router-link
                                to="/pages/ReportPostalItemsSalesReconciliation"
                                title="DBSA BANK RECONCILIATION REPORT "
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>DBSA BANK RECONCILIATION REPORT</span>
                            </router-link>

                        </div>

                        <!-- WEEKLY INVOICE RECONCILIATION REPORTS END -->

                        <!-- OTHER REPORTS START -->
                        <a
                            class="subtrigger"
                            title="OTHER REPORTS"
                        >
                            <i class="material-icons">more_horiz</i>
                            <span>OTHER REPORTS</span>
                        </a>

                        <div class="submenu">

                            <router-link
                                to="/pages/ReportsDelivery"
                                title="DELIVERY REPORTS"
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>DELIVERY REPORTS</span>
                            </router-link>

                            <router-link
                                to="/pages/ReportsDeliveryPercentage"
                                title="DELIVERY PERCENTAGE REPORTS"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>DELIVERY PERCENTAGE REPORTS</span>
                            </router-link>

                            <router-link
                                to="/pages/ReportsDeliveryPerformance"
                                title="DELIVERY PERFOMANCE REPORTS"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>DELIVERY PERFORMANCE REPORTS</span>
                            </router-link>

                            <router-link
                                to="/pages/ReportDispatchManagerLogs"
                                title="Dispatch Manager Logs "
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>DISPATCH MANAGER API LOGS</span>
                            </router-link>

                            <router-link
                                to="/pages/ReportIntegrationGatewayLogs"
                                title="Integration Gateway Logs "
                                v-if="auth().user_type != 'user'"
                            >
                                <span class="nested-sub-menu"></span>
                                <span>INTEGRATION GATEWAY API LOGS</span>
                            </router-link>

                        </div>

                        <!-- OTHER REPORTS END -->
                    </div>

                    <router-link
                        to="/pages/AddressBook"
                        title="ADDRESS BOOK"
                        v-if="auth().user_type == 'super_admin'"
                    >
                        <i class="material-icons">account_box</i>
                        <span>ADDRESS BOOK</span>
                    </router-link>

                    <router-link
                        to="/pages/PersonalAddressBook"
                        title="ADDRESS BOOK"
                        v-if="
                            auth().user_type == 'hub_admin' ||
                                auth().user_type == 'hub_user' ||
                                auth().user_type == 'user'
                        "
                    >
                        <i class="material-icons">account_box</i>
                        <span>ADDRESS BOOK</span>
                    </router-link>

                    <a
                        class="subtrigger"
                        title="REPORTS"
                        v-if="
                            auth().user_type == 'hub_admin' ||
                                auth().user_type == 'super_admin'
                        "
                    >
                        <i class="material-icons">equalizer</i>
                        <span>INVOICE CHECK</span>
                    </a>

                    <div class="submenu">
                        <router-link
                            to="/pages/InvoiceUpload"
                            title="INVOICE UPLOAD"
                            v-if="auth().user_type == 'hub_admin'"
                        >
                            <i class="material-icons">description</i>
                            <span>INVOICE UPLOAD</span>
                        </router-link>

                        <!-- <router-link
                            to="/pages/CustomerInvoiceReconciliation"
                            title="CUSTOMER INVOICE RECONCILIATION"
                        >
                            <i class="material-icons">equalizer</i>
                            <span>CUSTOMER</span>
                        </router-link> -->

                        <router-link
                            to="/pages/CarrierInvoiceReconciliation"
                            title="CARRIER INVOICE RECONCILIATION"
                        >
                            <i class="material-icons">receipt</i>
                            <span>COURIER</span>
                        </router-link>

                        <router-link
                            to="/pages/InvoiceReconciliation"
                            title="INVOICE RECONCILIATION"
                            v-if="auth().user_type == 'hub_admin'"
                        >
                            <i class="material-icons">sync</i>
                            <span>INVOICE RECONCILIATION</span>
                        </router-link>
                    </div>

                    <router-link
                        to="/pages/ProfileSettings"
                        title="PROFILE AND SETTINGS"
                    >
                        <i class="material-icons">settings_applications</i>
                        <span>PROFILE AND SETTINGS</span>
                    </router-link>

                    <router-link
                        to="/pages/PasswordManagement"
                        title="PASSWORD MANAGEMENT"
                        v-if="auth().user_type == 'super_admin'"
                    >
                        <i class="material-icons">network_locked</i>
                        <span>PASSWORD MANAGEMENT</span>
                    </router-link>

                    <hr v-if="auth().user_type == 'super_admin'" />

                    <a
                        class="subtrigger"
                        title="MASTER DATA"
                        v-if="auth().user_type == 'super_admin'"
                    >
                        <i class="material-icons">call_to_action</i>
                        <span>MASTER DATA</span>
                    </a>

                    <div class="submenu">
                        <router-link
                            to="/pages/ModeOfDispatch"
                            title="MODE OF DISPATCH"
                        >
                            <i class="material-icons">code</i>
                            <span>MODE OF DISPATCH</span>
                        </router-link>
                        <router-link
                            to="/pages/TypeOfGood"
                            title="TYPE OF GOODS"
                        >
                            <i class="material-icons">code</i>
                            <span>TYPE OF GOODS</span>
                        </router-link>

                        <router-link
                            to="/pages/CarrierServiceName"
                            title="CARRIER SERVICE NAME"
                        >
                            <i class="material-icons">code</i>
                            <span> CARRIER SERVICE NAME</span>
                        </router-link>

                        <router-link to="/pages/Carriers" title="CARRIERS">
                            <i class="material-icons">code</i>
                            <span>CARRIERS</span>
                        </router-link>

                        <router-link
                            to="/pages/TransitTime"
                            title="TRANSIT TIME"
                        >
                            <i class="material-icons">code</i>
                            <span>TRANSIT TIME</span>
                        </router-link>

                        <!--   <router-link to="/pages/CarrierServices" title="CARRIER SERVICES">
						<i class="material-icons">code</i>
						<span>CARRIER SERVICES</span>
						</router-link> -->

                        <router-link to="/pages/Unit" title="BRANCH">
                            <i class="material-icons">code</i>
                            <span>BRANCH</span>
                        </router-link>
                        <!-- <router-link to="/pages/Division" title="DIVISION">
						<i class="material-icons">code</i>
						<span>DIVISION</span>
						</router-link>
						<router-link to="/pages/Hub" title="HUB">
						<i class="material-icons">code</i>
						<span>HUB</span>
						</router-link> -->
                        <router-link to="/pages/CostCenter" title="COST CENTER">
                            <i class="material-icons">code</i>
                            <span>COST CENTER</span>
                        </router-link>
                        <router-link
                            to="/pages/DepartmentLocations"
                            title="DEPARTMENT LOCATIONS"
                        >
                            <i class="material-icons">code</i>
                            <span>DEPARTMENT/LOCATIONS</span>
                        </router-link>
                        <router-link
                            to="/pages/MailroomLocations"
                            title="MAILROOM LOCATIONS"
                        >
                            <i class="material-icons">code</i>
                            <span>HUB/MAILROOM LOCATIONS</span>
                        </router-link>
                        <router-link
                            to="/pages/EmailTemplate"
                            title="EMAIL TEMPLATE"
                        >
                            <i class="material-icons">code</i>
                            <span>EMAIL TEMPLATE</span>
                        </router-link>

                        <router-link
                            to="/pages/PostalDocumentType"
                            title="POSTAL DOCUMENT TYPE"
                        >
                            <i class="material-icons">code</i>
                            <span>POSTAL DOCUMENT TYPE</span>
                        </router-link>

                        <router-link
                            to="/pages/HolidayCalender"
                            title="HOLIDAYS"
                        >
                            <i class="material-icons">code</i>
                            <span>HOLIDAYS</span>
                        </router-link>
                    </div>

                    <router-link
                        to="/pages/UserServiceBudget"
                        title="USER SERVICE BUDGET"
                        v-if="
                            auth().user_type == 'super_admin' ||
                                auth().user_type != 'user' ||
                                auth().user_type == 'hub_admin'
                        "
                    >
                        <i class="material-icons">code</i>
                        <span>USER SERVICE BUDGET</span>
                    </router-link>

                    <router-link
                        to="/pages/UserManagement"
                        title="USER MANAGEMENT"
                        v-if="auth().user_type == 'super_admin'"
                    >
                        <i class="material-icons">account_circle</i>
                        <span>USER MANAGEMENT</span>
                    </router-link>

                    <router-link
                        to="/pages/help"
                        title="HELP"
                        v-if="auth().user_type != 'customer_service_user'"
                    >
                        <i class="material-icons">help</i>
                        <span>HELP</span>
                    </router-link>

                    <router-link
                        to="/pages/faq"
                        title="FAQ"
                        v-if="auth().user_type != 'customer_service_user'"
                    >
                        <i class="material-icons">help</i>
                        <span>FAQ</span>
                    </router-link>

                    <router-link
                        to="/pages/support"
                        title="SUPPORT"
                        v-if="auth().user_type != 'customer_service_user'"
                    >
                        <i class="material-icons">headset_mic</i>
                        <span>SUPPORT</span>
                    </router-link>

                    <a
                        title="DISPATCH MANAGER"
                        v-if="
                            (user.token && auth().user_type == 'super_admin') ||
                                auth().user_type == 'hub_admin'
                        "
                        :href="user.token"
                        target="_blank"
                    >
                        <i class="material-icons">link</i>
                        <span>DISPATCH MANAGER</span>
                    </a>

                    <a
                        title="GPS LIVE TRACKER"
                        :href="gps_live_tracker"
                        v-if="auth().user_type != 'user'">
                        <i class="material-icons">gps_fixed</i>
                        <span>GPS LIVE TRACKER</span>
                    </a>

                    <div class="copyright-content">
                        Copyright Drake Business Logistics © 2020-{{currentYear}}
                    </div>
                </nav>
            </section>
        </div>
        <div class="content-main">
            <MainPanel />
        </div>
    </div>
    <!--sidebar ends-->
</template>

<script>
import { ConfigurationEnum } from "@/enums";
import MainPanel from "./MainPanel.vue";
import jQuery from "jquery";
import { mapGetters, mapActions } from 'vuex'

const $ = jQuery;

export default {
    name: "HelloWorld",
    components: {
        MainPanel,
    },
    data() {
        return {
            usergood: "",
            user: {},
            outboundEnabled: 1,
            gps_live_tracker: process.env.VUE_APP_GPS_LIVE_TRACKER,
            currentYear: new Date().getFullYear(),
        };
    },
    computed: {
        ...mapGetters({
            configurations: 'configuration/configurations',
        }),
    },
    async created() {
        await this.setConfigurations();
        await this.fetchUser();
        await this.fetchusergood();

        try {
            if (this.auth().is_authenticated != 1) {
                this.axios.post("api/auth/logout");
                throw "Unauthorized access";
            }
        } catch (e) {
            localStorage.clear();
            this.toast.error("You must log in first");
            this.$router.replace("/");
        }

        window.checkIdleSession = setInterval(async() => await this.checkSessionTimeout, 20000);
        this.outboundEnabled = this.configurations[ConfigurationEnum.OUTBOUND];
    },
    mounted() {
        $(function() {
            $(".subtrigger").click(function() {
                $(this)
                    .next(".submenu")
                    .stop()
                    .slideToggle();
            });

            $(".menutrigger").click(function() {
                $(
                    ".sidebar,.main_panel,nav  a  i.material-icons, .sidebar section div, .sidebar section a.subtrigger, .sidebar nav a span,.sidebar .logo_div img,.sidebar section .submenu a"
                ).toggleClass("activeT");
                /* 
				if ($(".sidebar").hasClass("activeT")) {
					$(".transmittal_slip_filter").addClass("activeT");
				} else {
					$(".transmittal_slip_filter").removeClass("activeT");
				} 
				*/
            });
        });
    },
    methods: {
        ...mapActions({
			setConfigurations: 'configuration/setConfigurations',
		}),
        async checkSessionTimeout() {
            let idletime = 0;

            if (process.env.VUE_APP_IDLE_TIMEOUT) {
                idletime = process.env.VUE_APP_IDLE_TIMEOUT * 1000 * 60;
            } else {
                idletime = 3600000;
            }

            if (
                localStorage.last_active > Date.now() ||
                localStorage.last_active < Date.now() - idletime
            ) {
                await this.axios.post("api/auth/logout");
                localStorage.clear();
                this.$router.replace("/");
            }
        },
        async fetchUser() {
            
            try {
                const response = await this.axios.get('api/user/data');
                if (response) {
                    this.user = response.data.msg.division;
                }
            }
            catch (e) {
                console.log(e);
            }
        },
        async fetchusergood() {

            try {
                const response = await this.axios.get('/api/user/data')
                if (response) {
                    this.usergood = response.data.msg;
                }
            }
            catch (e) {
                console.log(e);
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
/*menu triggered ----------------------------------- css*/
.sidebar-version {
    text-align: right;
    padding-right: 10px;
    font-size: 15px;
}
.sidebar section a.subtrigger.activeT {
    background: none;
}
nav {
    padding-left: 10px;
}
nav a.router-link-exact-active {
    color: #06a5ed !important;
    border-left: 5px solid #06a5ed;
}
nav a i.material-icons {
    position: relative;
    left: 0;
    transition: left 0.5s;
}
nav a i.material-icons.activeT {
    left: 235px;
}
.sidebar .logo_div img {
    opacity: 1;
    transition: opacity 0.5s;
}
.sidebar .logo_div img.activeT {
    opacity: 0;
}
.sidebar nav a span {
    opacity: 1;
    transition: opacity 0.5s;
}
.sidebar nav a span.activeT {
    opacity: 0;
}
.sidebar section nav div.submenu a {
    transition: padding 0.5s;
}
.sidebar section nav div.submenu.activeT a {
    padding-left: 0;
}
.sidebar {
    left: 0;
    transition: left 0.5s;
}
.sidebar.activeT {
    left: -250px;
}
.main_panel {
    transition: margin-left 0.5s, width 0.5s;
}
.main_panel.activeT {
    width: calc(100% - 50px);
    margin-left: 50px;
}

.main_panel > header {
    transition: width 0.5s, left 0.5s;
}
.main_panel.activeT > header {
    left: 50px;
    width: calc(100% - 50px);
}
.main_panel .under_header {
    transition: width 0.5s, left 0.5s;
}
.main_panel.activeT .under_header {
    left: 50px;
    width: calc(100% - 50px);
}

.main_panel .transmittal_slip_filter {
    transition: left 0.5s, width 0.5s;
}
.main_panel.activeT .transmittal_slip_filter {
    left: 50px;
    width: calc(100% - 50px);
}

/*menu triggered ----------------------------------- css ENDS*/
.main {
    width: 100%;
    display: flex;
}
.content-main {
    width: 100%;
    overflow: auto;
    height: auto;
}
.sidebar {
    width: 300px;
    height: 100%;
    background: #004a7c;
    color: #fff;
    flex-shrink: 0;
    overflow-y: scroll;
    position: fixed;
    .logo_div {
        width: 100%;
        height: auto;
        padding: 20px 0;
        background: #004a7c;
        border-bottom: 2px solid #003a70;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
        padding-bottom: 5px;
    }
}
.sidebar section {
    margin: 10px 0 20px;
    header {
        font-size: 18px;
        padding: 0 0 10px 20px;
    }
    a {
        display: block;
        width: 100%;
        height: 60px;
        line-height: 60px;
        padding: 0;
        box-sizing: border-box;
        font-weight: 300;
        cursor: pointer;
        font-size: 13px;
        color: #fff;
        &.subtrigger {
            background: url(../assets/ico-down-arrow.png) no-repeat;
            background-position: right 20px top 50%;
        }
        &:hover {
            color: #ddd;
            .icon {
                background-position: 0 -33px;
            }
        }
        .icon {
            width: 32px;
            height: 32px;
            background-image: url(../assets/ico_settings.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: 0 -0;
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
        }
    }
    .submenu {
        background: #063657;
        display: none;
        a {
            color: #ccc;
            border-bottom: 1px solid #004a7c;
            padding-left: 15px;
            &:hover {
                color: #fff;
            }
        }
    }
}
.material-icons {
    font-size: 18px;
    position: relative;
    top: 5px;
    margin: 0 10px;
}
.copyright-content {
    position: relative;
    bottom: 0;
    font-size: 12px;
    width: 300px;
    left: 0;
    padding: 5px;
    margin-left: 10px;
}

.nested-sub-menu {
    margin-left: 33px !important;
}
</style>
